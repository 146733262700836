/* CC Color Palette --------------------------------------------------------- */

$cc-color-oha: rgb(38, 41, 50);
$cc-color-continuity: rgb(93, 105, 103);
$cc-color-continuity-pulse: rgb(4, 90, 163);
$cc-color-pulse: rgb(138, 204, 54);

$cc-color-introduction: rgb(4, 90, 163);
$cc-color-summary: rgb(38, 41, 50);
$cc-color-strategy: rgb(179, 205, 227);
$cc-color-leadership: rgb(184, 184, 184);
$cc-color-management: rgb(80, 140, 191);
$cc-color-operations: rgb(128, 178, 130);
$cc-color-workforce: rgb(129, 172, 209);
$cc-color-culture: rgb(179, 209, 180);
$cc-color-engagement: rgb(93, 105, 103);
$cc-color-next-steps: rgb(68, 113, 195);

/* CC Color Palette Gradient ------------------------------------------------ */

$cc-color-continuity-pulse-lighter-1: rgb(41, 136, 206);
$cc-color-continuity-pulse-lighter-2: rgb(106, 185, 234);

$cc-color-pulse-lighter-1: rgb(178, 229, 110);
$cc-color-pulse-lighter-2: rgb(208, 244, 149);

/* CC Color Palette Extended ------------------------------------------------ */

$cc-color-continuity-lighter-1: rgb(133, 130, 159);

/* Interface Element Color Palette ------------------------------------------ */

$cc-interface-color-sidebar-heading-border-bottom: rgb(100, 111, 121);
$cc-interface-color-nav-pill-inactive: rgb(108, 117, 125);

/* Old Color Palette -------------------------------------------------------- */

$hard-blue: rgb(0, 82, 155);
$bright-blue: rgb(0, 123, 255);
$aquatic-blue: rgb(25, 151, 198);
$light-background: white;
$dark-text: rgb(77, 77, 77);
$light-text: white;
$standard-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3);

/* Miscellaneous Colors ----------------------------------------------------- */

$accordion-wrapper-hover-color: #f6f8f9;
$accordion-button-border-color: #b7bfc6;
$accordion-button-hover-color: #25e8c8;
$accordion-button-hover-background-color: #e2fffa;
$accordion-button-closed-color: #00bf9c;
$navbar-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$navbar-background-dark: #252830;
$navbar-background-light: rgba(255, 255, 255, 0.92);
$sidebar-background: rgb(222, 233, 241);
$sidebar-heading-color: #51586a;
$sidebar-nav-color: #777;
$sidebar-nav-color-hover: #333;
$container-border-color: rgba(0, 0, 0, 0.125);
$footer-bar-background: #f8f9fa;
$footer-border-color: #dee2e6;
$preview-box-shadow: 0 6px 3px -3px rgba(0, 0, 0, 0.3);
$dark-background: black;
$phase-preview-background: #436a8f;
$phase-preview-background-hover: #3e6183;
$phase-preview-background-discover: #ffce3e;
$phase-preview-background-discover-hover: #ebbe39;
$phase-preview-background-develop: #0fb1d9;
$phase-preview-background-develop-hover: #0fa2c7;
$phase-preview-background-implement: #164675;
$phase-preview-background-implement-hover: #133b63;
$cc-process-widget-btn-background: #0062cc;
$bootstrap-form-control-border-color: #ced4da;