@import "colors";

/* Global Elements ---------------------------------------------------------- */

body {
    background-image: url("/static/images/cc-background-normal.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* Cards -------------------------------------------------------------------- */

.cc-unsupported-card {
    box-shadow: $standard-shadow;
    border-radius: 0;
    margin-left: 2em;
    margin-right: 2em;
    font-weight: 300;

    #cc-pulse-logo-unsupported {
        display: block;
        margin: 0 auto;
        max-width: 300px;
    }

    .card-header {
        h1 {
            margin: 0;
        }
    }
}

/* Forms -------------------------------------------------------------------- */

.form-signin {
    padding: 0;
    border-radius: 0;
    box-shadow: $standard-shadow;

    .card-header {
        img {
            display: block;
            width: auto;
            max-width: 240px;
            margin: 0 auto;
        }
    }

    .card-footer {
        font-weight: 300;
    }

    .form-control {
        &:focus {
            border-color: $bootstrap-form-control-border-color;
            box-shadow: none;
        }
    }

    #password {
        margin-bottom: 1.25rem;
    }

    .cc-forgot-password-link {
        display: block;
        font-weight: 300;
        font-size: 0.6em;
    }
}